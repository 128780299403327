import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { LinkRowWithNote } from '../components/Notes/LinkRow';
import { slugToTitle } from '../../utils/slug';
import { sortNodesByYear } from '../utility/sort';
import Subheader from '../components/Subheader';
import { getIconSrc } from '../utility/icons';
import { getTheme } from '../utility/theme';
import { RowReveal } from '../components/Notes/RowReveal';
import { MAX_TABLET_WIDTH } from '../constants';

const Container = styled.p`
  margin-right: 0.4rem;
  display: flex;
  align-items: flex-start;

  flex-shrink: 0; /* prevent icons from narrowing */
  img {
    background: none;
    height: 1rem;
  }
  & p {
    margin-bottom: 0 !important;
  }
`;
const getIcon = (type: string): JSX.Element => {
  const theme = getTheme();
  const src = getIconSrc(type, theme);
  return (
    <Container>
      <img src={src} />
    </Container>
  );
};

const Year = styled.span`
  font-style: italic;
  font-size: 0.8rem;
  margin-left: 0.25rem;
`;


export const generateSection = (rows, showAll) => {
  return rows.map(r => {
    const { type, display, note, slug } = r.fields;

    const Icon = getIcon(type);
    return (
      <RowReveal
        key={r.name}
        fields={r.fields}
        showAll={showAll}
      />

      // <LinkRowWithNote key={r.name}>
      //   {/* {Icon} */}
      //   <div>
      //     <Link to={`/${r.fields.slug}`}>{display}</Link>
      //     {/* <details>
      //       <summary>
      //         <Year>{r.fields.journalYear}</Year>
      //       </summary>
      //       <p>{note}</p>
      //     </details> */}
      //   </div>
      // </LinkRowWithNote>
    );
  });
};

export const StickyTop = styled.div`
  position: fixed;
  height: 3rem;
  z-index: 10;
  top: 0;
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-style: bold;
  right: 1rem;

  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    left: 10rem;
  }
`
const Directory = (props: any) => {
  const base = '/';
  const CURRENT_DIR = props.uri.slice(base.length);

  const data = useStaticQuery(graphql`
    query DirQueryDirectory {
      allDirectory {
        nodes {
          absolutePath
          relativePath
          relativeDirectory
          dir
          name
          fields {
            slug
            relativeDirectorySlug
            type
            name
          }
        }
      }
      allFile {
        nodes {
          absolutePath
          dir
          relativeDirectory
          relativePath
          name
          publicURL
          fields {
            slug
            relativeDirectorySlug
            type
            name
            display
            year
            significance
            title
            note
            journalYear
          }
        }
      }
    }
  `);
  const pageTitle = slugToTitle(props.pageContext.name);
  const raw = [...data.allDirectory.nodes, ...data.allFile.nodes];
  const filtered = raw.filter(r => r.fields && r.fields.relativeDirectorySlug === CURRENT_DIR).sort(sortNodesByYear);

  const landmark = filtered.filter(n => n.fields.significance === 'Landmark');
  const important = filtered.filter(n => n.fields.significance === 'Important');
  const regular = filtered.filter(n => n.fields.significance === 'Regular');

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    function onKeyPress(e) {
      if (e.key === 'e') {
        setShowAll(!showAll)
      }
    }
    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    }
  });

  return (
    <Layout>
      <SEO title={pageTitle} />
      <Subheader crumbs={props.pageContext.crumbs} />
      <h1 data-id="title">{pageTitle}</h1>

      <StickyTop>
        +
      </StickyTop>

      <h2>Landmark</h2>
      {generateSection(landmark, showAll)}

      <h2>Important</h2>
      {generateSection(important, showAll)}

      <h2>Regular</h2>
      {generateSection(regular, showAll)}
    </Layout>
  );
};

export default Directory;
