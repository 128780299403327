interface Node {
  fields: {
    name: string;
    year: string;
    [field: string]: string;
  };
}
export const sortNodesByYear = (a: Node, b: Node) => {
  if (a.fields.year < b.fields.year) {
    return 1;
  }
  if (a.fields.year > b.fields.year) {
    return -1;
  }
  return 0;
};
export const sortNodesBySystem = (a: Node, b: Node) => {
  if (a.fields.relativeDirectorySlug < b.fields.relativeDirectorySlug) {
    return -1;
  }
  if (a.fields.relativeDirectorySlug > b.fields.relativeDirectorySlug) {
    return 1;
  }
  return 0;
};

export const sortNodesIndex = (a: Node, b: Node) => {
  if (a.fields.name > b.fields.name) {
    return 1;
  }
  if (a.fields.name < b.fields.name) {
    return -1;
  }
  return 0;
};
