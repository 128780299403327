import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';

import { LinkRowWithNote } from './LinkRow';
import { Link, graphql, useStaticQuery } from 'gatsby';


const Journal = styled.span`
  font-style: italic;
  font-size: 0.7rem;
  margin-right: 1rem;
`

const COLORS = {
  red: '#f4303d',
  orange: '#f7941d',
  yellow: '#fede59',
  green: '#97c259',
  blue: '#38b6ff',
  purple: '#d0b7d2',
  pink: '#fad5e5',
  gray: '#808080',
  darkgray: '#4d44d',

}


const SYSTEM_OTHER = { long: 'other', short: 'Other', color: 'white' };
const SYSTEMS_MAP = [
  { long: 'airway', short: 'AW', color: COLORS.blue },
  { long: 'anaesthesia', short: 'Anaes', color: COLORS.blue },
  { long: 'cardiovascular', short: 'CV', color: COLORS.red },
  { long: 'respiratory', short: 'RS', color: COLORS.blue },
  { long: 'neurological', short: 'NS', color: COLORS.yellow },
  { long: 'pain', short: 'Pain', color: COLORS.yellow },
  { long: 'gastrointestinal', short: 'GIT', color: COLORS.green },
  { long: 'renal', short: 'Renal', color: COLORS.yellow },
  { long: 'endocrine', short: 'Endo', color: COLORS.yellow },
  { long: 'obstetrics', short: 'O+G', color: COLORS.pink },
  { long: 'haem', short: 'Haem', color: COLORS.pink },
  { long: 'immune', short: 'Immune', color: COLORS.pink },
  { long: 'musculoskeletal', short: 'MSk', color: COLORS.pink },
  SYSTEM_OTHER,
]

export const getSystemsAbbreviation = (raw) => {
  const abbrevArr = _.filter(SYSTEMS_MAP, (system) => {

    if (raw.toLowerCase().includes(system.long)) {
      return true;
    }
    return false;
  });
  if (abbrevArr.length) {
    return abbrevArr[0];
  }
  return SYSTEM_OTHER;
}

type AbbrevProps = {
  cell?: boolean;
  color?: string;
}
export const Abbrev = styled.div<AbbrevProps>`
  font-size: ${p => {
    return p.cell ? '0.5rem' : `0.9rem`;
  }};
  border: 1px solid ${p => p.color};
  border-radius: 3px;
  padding: 2px 6px;
  margin-right: ${p => {
    return p.cell ? 0 : `0.5rem`;
  }};
  margin-bottom: ${p => {
    return p.cell ? 0 : `1rem`;
  }};
`;

const Row2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
`

export const RowReveal = props => {
  const { display, slug, note, journalYear } = props.fields;
  const { showAll } = props;

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  }

  const abbrev = getSystemsAbbreviation(props.fields.relativeDirectorySlug);


  return (
    <LinkRowWithNote onClick={handleClick}>
        <Link to={`/${slug}`}>
          {display}
        </Link>

      <Row2>
      <Journal>{journalYear}</Journal>
        <Abbrev color={abbrev.color} cell={true}>
          {abbrev.short}
        </Abbrev>
      </Row2>

      {(show || showAll )&& (
        <div>
          {note}
        </div>
      )}

    </LinkRowWithNote>
  )
}
