import styled from '@emotion/styled';
import { COLOR, MAX_TABLET_WIDTH } from '../../constants';

export const LinkRow = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
  margin: 0.5rem 0;
  justify-content: start;

  > p {
    margin-bottom: 0;
  }
`;

export const SystemsCell = styled.div`
  flex: 1 0 10rem;
  border: 1px solid white;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.3rem 0.67rem;
  border-radius: 4px;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    height: 3rem;
  }

`
export const SystemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

`

export const LinkRowWithNote = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: start;
  border: 1px solid ${COLOR.GRAY_500};
  border-radius: 4px;
  padding: 0.5rem;
  padding-right: 2rem;
  /* flex: 0 0 32%; */
  flex: 0 0 100%;
  cursor: pointer;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    /* flex: 0 0 47%; */
  }

  > p {
    margin-bottom: 0;
  }

  summary {
    outline: none !important;
    margin-bottom: 0;
  }
`;

export const Heading = styled.div`
  cursor: pointer;
`

